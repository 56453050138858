<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <!-- <el-form-item label="赛事" prop="team_status">
        <el-select
          v-model="match_subject_id"
          placeholder="请选择"
          @change="searchClick_Match"
        >
          <el-option
            v-for="item in optionsMatch"
            :key="item._id"
            :label="item.match_name"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->

      <!-- <el-form-item label="当前状态">
        <el-select
          @change="searchClick()"
          v-model="searchForm.status"
          placeholder="请选择查询条件"
        >
          <el-option label="正常" :value="0"></el-option>
          <el-option label="回收站" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item> -->
      <!-- <el-button type="success" @click="handleQuery">查询</el-button> -->
      <!-- </el-form-item> -->

      <el-form-item>
        <el-button type="primary" @click="addMatchRule">新建赛段</el-button>
      </el-form-item>

      <!-- <el-form-item>
        <el-button type="primary" @click="searchClick">回收站</el-button>
      </el-form-item> -->
    </el-form>
    <el-table :data="dataTable" style="margin-top: 20px" border>
      <template v-for="item in dataList">
        <el-table-column
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click.native.stop="handleEdit(scope.row)"
            >赛段编辑</el-button
          >
             <!-- v-if="
              matchType === 'match_s4' && scope.row.season_name === '预选赛'
            " -->
          <el-button
            @click.native.stop="handleTeam(scope.row)"
            v-if="scope.row.is_team_mange">
            队伍管理
          </el-button>
          <!-- 分组管理 -->
          <el-button
              @click.native.stop="handleGrouping(scope.row)"
              v-if="scope.row.is_group_manage">
            分组管理
          </el-button>
           <!-- 编辑场次 || 生成场次按钮 -->
          <el-button
            @click.native.stop="handleSession(scope.row)"
            v-if="scope.row.is_generate_session">
            {{ scope.row.is_update_session ? "编辑场次" : "生成场次" }}
          </el-button>
          <el-button
            @click.native.stop="handleCourseMange(scope.row)"
            v-if="scope.row.is_update_session"
            >场次管理</el-button
          >
          <el-button
            v-if="scope.row.type_id !== 'audition'"
            @click.native.stop="handleScore(scope.row)"
            >排行榜</el-button
          >
          <el-button
            v-if="
              matchType.includes('match_s') && scope.row.season_name === '预选赛'
            "
            @click.native.stop="prediction(scope.row)"
            >预测管理</el-button
          >
           <el-button
                @click.native.stop="handleCreateTestTeams(scope.row)"
                v-if="scope.row.type_id !== 'audition'"
            >生成测试队伍</el-button
          >
         
          <!-- <el-button @click.native.stop="handleEdit(scope.row)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @current-change="handleQuery"
        @size-change="handleSizeChange"
        :page-sizes="[10, 15, 20, 30, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="pager.pageNum"
        layout="sizes, prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import perButton from "@/components/perm/perButton";
// import { randomStr } from "@/utils/randomStr";
import { matchType_sect_num, matchType_person_num } from "./public.js/toolbarOptions";
export default {
  name: "super_list",
  // components: {
  //   perButton,
  // },
  computed: {
    ...mapState("match", ["match_types", "subject_match_name"]),
    isNotS1_S2() {
      //在门派联赛s1和s2的时候 需要隐藏
      let match_type_names = ["match_s1", "match_s2"];
      let new_match_type = this.$route.query.match_type;
      if (match_type_names.indexOf(new_match_type) > -1) {
        return false;
      } else {
        return true;
      }
    },
    isProduction() {
      if (process.env.NODE_ENV === "production") {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      optionsMatch: [],
      sect_num: 0,
      person_num:0,
      pager: {
        total: 0,
        pageNum: 1,
      },
      ids: [],
      type_id: "",
      match_subject_id: "",
      loading: false,
      pageSize: 15,
      new_match_id: "",
      qrcodeVisible: false,
      qrcodeData: {
        match_id: "",
        expired: false,
        src: "",
      },

      searchForm: {
        year: "",
        status: 0,
      },
      dataTable: [],
      dataList: [
        {
          prop: "season_name",
          label: "赛事阶段",
          width: 300,
        },
        {
          prop: "match_time",
          label: "赛事状态",
          width: 200,
          formatter: (row) => {
            //状态使用sign_up_start来区分赛事，如果是预选赛则有sign_up_start，否则就是大区赛联赛等
            let curentTime = new Date();

            //预选赛
            if (row.type_id === "s1" || row.type_id === "s2") {
              if (curentTime < new Date(row.match_time.sign_up_start)) {
                return "未开始";
              } else if (curentTime < new Date(row.match_time.review_start)) {
                return "报名期";
              } else if (curentTime < new Date(row.match_time.match_start)) {
                return "审核期";
              } else if (curentTime < new Date(row.match_time.match_end)) {
                return "比赛期";
              } else {
                return "已结束";
              }
            } else if (
              row.type_id === "qualifier"
            ) {
              if (curentTime < new Date(row.match_time.sign_up_start)) {
                return "未开始";
              } else if (curentTime < new Date(row.match_time.sign_up_end)) {
                return "报名期";
              } else if (curentTime < new Date(row.match_time.match_end)) {
                return "比赛中";
              } else {
                return "已结束";
              }
            } else {
              let curentTime = new Date();
              //联赛赛事阶段
              if (curentTime < new Date(row.match_time.match_start)) {
                return "未开始";
              } else if (curentTime < new Date(row.match_time.match_end)) {
                return "比赛中";
              } else {
                return "已结束";
              }
            }
          },
        },
        {
          prop: "status",
          label: "状态",
          width: 80,
          formatter: (row) => {
            switch (row.status) {
              case 0:
                return "正常";
              case 1:
                return "回收站";
              default:
                return "";
            }
          },
        },
      ],
      matchType: "", //赛事类型
    };
  },
  methods: {
    /**
     * 队伍管理
     */
    teamManage (row) {
      console.log('~~~~~~赛段管理', row)
      return (row.season_name !== '预选赛') || row.match_name === '门派联赛第四赛季'
    },

    /**
     * 分组管理
     */
    groupManage (row) {
      // if (row.is_team_mange === true) return false;

    },

    /**
     * 编辑场次 || 生成场次
     */
    isGenerateSession () {

    },
    /**
     * 场次管理
     */
    courseManage () {

    },

    /**
     * 排行榜
     */
    rankManage () {

    },

    /**
     * 预测管理
     */
    predictionManage () {

    },

    /**
     * 测试管理
     */
    testManage () {

    },

     sect_num_fn(e) {
      this.sect_num =  matchType_sect_num[e] || 0 ;
    },
    person_num_fn(e) {
      this.person_num = matchType_person_num[e] || 0 ;
    },
    // 预测管理
    prediction(row) {
      console.log(row);
      this.removeTabByPath(`/match/info/info_s1/pointsManage`);
      this.$router.push({
        path: `/match/info/info_s1/pointsManage`,
        query: {
          match_id: this.new_match_id,
          stage: row._id,
          type_id: row.type_id,
          season_id: row._id,
          match_type: this.matchType,
        },
      });
    },
    // 生成测试队伍
    handleCreateTestTeams(row) {
      this.sect_num_fn(row.type_id)
      this.person_num_fn(row.type_id)
      console.log(this.sect_num,this.person_num);
      this.$http.postByUrl(`/admin/match/s1/create_test_teams`, {
        match_id: this.new_match_id,
        season_id: row._id,
        sect_num: this.sect_num,
        person_num: this.person_num
      });
    },
    //新建赛段
    addMatchRule() {
      this.removeTabByPath(`/match/subject/match_segment`);
      this.$router.push({
        path: `/match/subject/match_segment`,
        query: {
          match_id: this.new_match_id,
          match_type: this.matchType,
          view_type: "add",
        },
      });
    },
    // 编辑场次，生成场次
    async handleSession(row) {
      this.removeTabByPath(`/match/subject/match_session`);
      this.$router.push({
        path: `/match/subject/match_session`,
        query: {
          match_type: this.matchType,
          match_id: this.new_match_id,
          _id: row._id,
          type_id: row.type_id,
          season_name: row.season_name,
        },
      });
    },
    // 场次管理
    async handleCourseMange(row) {
      this.removeTabByPath(`/match/subject/session`);
      this.$router.push({
        path: `/match/subject/session`,
        query: {
          match_id: this.new_match_id,
          _id: row._id,
          type_id: row.type_id,
          season_name: row.season_name,
          match_type: this.matchType,
        },
      });
    },

    //赛段编辑
    async handleEdit(row) {
      console.log("111", row);
      this.removeTabByPath(`/match/subject/match_segment`);
      this.$router.push({
        path: `/match/subject/match_segment`,
        query: {
          match_type: this.matchType,
          match_id: this.new_match_id,
          season_id: row._id,
          _id: row._id,
          type_id: row.type_id,
          view_type: "edit",
        },
      });
    },
    //分组管理
    async handleGrouping(row) {
      this.removeTabByPath(`/match/subject/gruopLeague`);
      this.$router.push({
        path: `/match/subject/gruopLeague`,
        query: {
          match_id: this.new_match_id,
          match_type: this.matchType,
          season_id: row._id,
          type_id: row.type_id,
        },
      });
    },
    //队伍管理
    async handleTeam(row) {
      this.removeTabByPath(`/match/subject/team`);
      this.$router.push({
        path: `/match/subject/team`,
        query: {
          match_id: this.new_match_id,
          season_id: row._id,
          type_id: row.type_id,
           match_type: this.matchType,
        },
      });
    },
    //排行榜
    handleScore(row) {
      console.log("sesson_id", row._id);
      this.removeTabByPath(`/match/subject/rank`);
      localStorage.setItem("match_segment", JSON.stringify(row));

      let match_id = row.old_match_id ? row.old_match_id : row.new_match_id;
      let season_id = row.old_season_id ? row.old_season_id : row._id;

      this.$router.push({
        path: `/match/subject/rank`,
        query: {
          match_id: match_id,
          type_id: row.type_id,
          season_name: row.season_name,
          season_id: season_id,
          take: row.take,
          match_type: this.$route.query.match_type,
        },
      });
    },
    searchClick() {
      this.handleQuery();
    },
    searchClick_Match() {
      this.$store.commit("match/setName", this.match_subject_id);
      this.handleQueryMatch();
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      this.pager.pageNum = 1;
      this.pager.total = 0;
      this.handleQuery();
    },

    async handleQuery() {
      const { pageNum } = this.pager;
      let res = await this.$http.match_subject.subject_detail({
        match_id: this.new_match_id,
        pageNum,
        pageSize: this.pageSize,
      });
      if (res.code == 0) {
        this.dataTable = res.data.data;
        this.pager = res.data.pager;
      }
    },
    //下拉赛事
    async handleQueryMatch() {
      //当选择了赛事时
      if (this.subject_match_name != "") {
        this.match_subject_id = this.subject_match_name;
        let res = await this.$http.match_subject.subject_list();
        //拿到请求数据
        if (res.code == 0) {
          this.optionsMatch = res.data;
          this.handleQuery();
        }
      } else {
        //直接获取请求
        let res = await this.$http.match_subject.subject_list();
        if (res.code == 0) {
          //赛事id赋值
          this.optionsMatch = res.data;
          if (res.data.length != 0) {
            this.match_subject_id = res.data[1]._id;
            this.handleQuery();
          }
        }
      }
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async created() {
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.new_match_id = this.$route.query.match_id;
    this.matchType = this.$route.query.match_type;
    console.log("match_type:", this.matchType);
    //下拉拿到赛事框
    // this.handleQueryMatch();
  },
  activated() {
    this.handleQuery();
  },
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px !important;
  height: 300px !important;
  line-height: 300px !important;
  text-align: center;
}
/deep/ .el-upload-dragger {
  width: 300px !important;
  height: 300px !important;
}
.avatar {
  width: 300px !important;
  height: 300px !important;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
  width: 300px !important;
  height: 300px !important;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}
</style>
